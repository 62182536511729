import Link from "next/link";
import styles from "./Destaque.module.scss";

interface Props {
  url?: string;
  text?: string;
  title: string;
  label?: string;
  height?: string;
  background: string;
  video?: string;
  smaller?: boolean;
}

export default function Destaque(props: Props) {
  const {
    url,
    text,
    title,
    label,
    background,
    height,
    video,
    smaller = false,
  } = props;

  return (
    <div
      className={styles.destaque}
      style={{ backgroundImage: `url(${background})`, height: `${height}px` }}
    >
      {video && (
        <video
          className={styles.destaque__video}
          autoPlay
          loop
          muted
          playsInline
          // poster={background}
          src={video}
        />
      )}
      <h3
        className={styles.destaque__title}
        // html
        dangerouslySetInnerHTML={{ __html: title }}
        style={{ fontSize: smaller ? "2rem" : "3rem" }}
      />
      {text && <p className={styles.destaque__text}>{text}</p>}
      {label && url && (
        <Link prefetch={false} href={url} className={styles.destaque__button}>
          {label}
        </Link>
      )}
    </div>
  );
}
